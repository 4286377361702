<template>
  <v-text-field v-model.number="val" type="number" label="Preis"/>
</template>

<script>
export default {
  name: 'PlugPriceEdit',
  props: {
    value: Number
  },
  data () {
    return {
      val: null
    }
  },
  watch: {
    value: {
      handler (value) {
        if (value) {
          this.val = value
        }
      },
      immediate: true
    },
    val (val) {
      this.$emit('input', val)
    }
  }
}
</script>
