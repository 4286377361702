<template>
  <v-card flat>
    <v-sheet v-if="postData && postData.schedule" color="grey" dark class="pa-2">{{ $t('scheduled_post') }}</v-sheet>
    <v-sheet v-if="postData && postData.reviewed === false" color="grey" dark class="pa-2">{{ $t('moderated_post') }}</v-sheet>

    <v-card-title class="d-flex align-start justify-space-between flex-nowrap">
      <div>
        <div>{{ postData ? postData.title : '' }}</div>
        <span v-if="postData && postData.schedule" class="caption caption-label">{{ postData.schedule | datetimeago }}</span>
      </div>
      <v-btn v-if="isPostEditable" icon @click="$emit('edit', postData)">
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text v-if="postData">
      <v-row>
        <v-col v-if="postData.img" cols="12" sm="4">
          <img-cmp :img="postData.img" />
        </v-col>
        <v-col cols="12" :sm="postData.img ? 8 : 12">
          <div v-if="postData.text" v-html="postData.text" class="html-text mb-3"/>
          <files-list v-if="postData.files && postData.files.length > 0" :files="postData.files" class="mb-3"/>
          <plug-price-view v-if="plugins.includes('price')" :item="postData" class="my-3"/>
          <plug-address-view v-if="plugins.includes('address')" :item="postData" short class="my-3"/>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import ImgCmp from '@/components/base/ImgCmp'
import PlugsMixin from '@/mixins/PlugsMixin'
import PlugAddressView from '@/components/plugs/PlugAddressView'
import PlugPriceView from '@/components/plugs/PlugPriceView'

export default {
  name: 'PostInline',
  components: { ImgCmp, PlugAddressView, PlugPriceView },
  mixins: [PlugsMixin],
  inject: ['config', 'posts'],
  props: {
    postId: String,
    post: Object
  },
  data () {
    return {
      postData: null
    }
  },
  computed: {
    user () {
      return this.postData?.userId ? {
        id: this.postData.userId,
        ...this.$root.users[this.postData.userId]
      } : null
    },
    isPostEditable () {
      return this.postData && (this.postData.userId === this.$root.userId || this.$root.isAdmin)
    },
  },
  methods: {
    async load () {
      this.$fb.db.doc('posts/' + this.postId).get()
        .then(doc => {
          this.postData = {
            id: this.postId,
            ...doc.data()
          }
          this.$emit('load', this.postData)
        })
    }
  },
  watch: {
    post (post) {
      if (post) {
        this.postData = { ...this.post }
      }
    },
    'posts.updatedPostId' (updatedPostId) {
      if (this.postId && this.postId === updatedPostId) {
        this.load()
      }
    }
  },
  created () {
    if (this.post) {
      this.postData = { ...this.post }
    } else {
      this.load()
    }
  }
}
</script>
