<template>
  <div v-if="enabled">
    <v-menu left offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list dense>
        <!-- <v-subheader>Regulation</v-subheader> -->
        <v-list-item :disabled="!canFilterPost" @click="showFilterDlg = true">
          <v-list-item-icon>
            <v-icon>mdi-emoticon-sad-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Gefällt mir nicht</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :disabled="!canFlagPost" @click="showFlagDlg = true">
          <v-list-item-icon>
            <v-icon>mdi-flag-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Beitrag melden</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :disabled="!canBlockUser" @click="showBlockDlg = true">
          <v-list-item-icon>
            <v-icon>mdi-account-cancel-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Benutzer blockieren</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog
      v-model="showFilterDlg"
      width="330"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Gefällt mir nicht</v-toolbar-title>
          <v-spacer/>
          <v-btn icon @click="showFilterDlg = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-3">
          Wenn dir ein Beitrag nicht gefällt, kannst du ihn ein für alle Mal ausblenden.
        </v-card-text>
        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn :loading="saving" @click="filterPost">Ausblenden</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showFlagDlg"
      width="330"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Beitrag melden</v-toolbar-title>
          <v-spacer/>
          <v-btn icon @click="showFlagDlg = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-3">
          <div class="mb-6">Wenn ein Beitrag anstössig ist, kannst du ihn melden, damit er überprüft wird.</div>
          <v-checkbox
            v-if="canFilterPost"
            v-model="flagAndFilter"
            label="Beitrag ausblenden"
          />
        </v-card-text>
        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn :loading="saving" @click="flagPost">Melden</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showBlockDlg"
      width="330"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Benutzer blockieren</v-toolbar-title>
          <v-spacer/>
          <v-btn icon @click="showBlockDlg = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-3">
          <div class="mb-6">Wenn dir Beiträge dieses Benutzers nicht gefallen, kannst du ihn blockieren. Danach werden dir keine Beiträge mehr von ihm angezeigt.</div>
          <div class="font-weight-bold mb-6">Benutzer: {{ postUser.name }}</div>
        </v-card-text>
        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn :loading="saving" @click="blockUser">Blockieren</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'PostObjectionMenu',
  props: {
    post: Object
  },
  data () {
    return {
      showFilterDlg: false,
      showFlagDlg: false,
      showBlockDlg: false,
      flagAndFilter: false,
      saving: false
    }
  },
  computed: {
    enabled () {
      return this.$cfg.objections && this.$root.userId !== this.post.userId // of course not for myself
    },
    postUser () {
      return this.$root.users[this.post.userId]
    },
    canFilterPost () {
      return !this.$root.user.filteredPostIds?.includes(this.post.id)
    },
    canFlagPost () {
      return !this.post.flaggedBy?.includes(this.$root.userId)
    },
    canBlockUser () {
      return !this.$root.user.blockedUserIds?.includes(this.post.userId)
    }
  },
  methods: {
    async filterPost () {
      this.saving = true
      await this.$fb.db.doc('users/' + this.$root.userId).update({
        filteredPostIds: this.$fb.fb.firestore.FieldValue.arrayUnion(this.post.id)
      })
      this.showFilterDlg = false
      this.saving = false
      this.proposeReload()
    },
    async flagPost () {
      this.saving = true
      if (this.flagAndFilter) {
        await this.$fb.db.doc('users/' + this.$root.userId).update({
          filteredPostIds: this.$fb.fb.firestore.FieldValue.arrayUnion(this.post.id)
        })
      }
      const flagPostFunc = this.$fb.fn.httpsCallable('flagPost')
      await flagPostFunc({
        id: this.post.id
      })
      this.showFlagDlg = false
      this.saving = false
      this.proposeReload()
    },
    async blockUser () {
      this.saving = true
      await this.$fb.db.doc('users/' + this.$root.userId).update({
        blockedUserIds: this.$fb.fb.firestore.FieldValue.arrayUnion(this.post.userId)
      })
      this.showBlockDlg = false
      this.saving = false
      this.proposeReload()
    },
    proposeReload () {
      // ugly but easier than refiltering underlying list(s), and hopefully seldom ;)
      // if(confirm('Die Änderungen werden erst nach neuem Laden sichtbar. Jetzt neu laden?')) {
        window.location.reload()
      // }
    }
  }
}
</script>

<style>

</style>