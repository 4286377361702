<template>
  <v-container>
    <v-chip v-if="updatedPostsIdx" color="primary" class="chip-abs" @click="showUpdated">
      <span class="px-2">{{ $t('has_new') }}</span>
    </v-chip>

    <v-alert
      v-if="!config.collection && $root.user.showTagsInfo"
      color="info"
      dark
      icon="mdi-information"
      elevation="2"
      @click="showHidden = !showHidden"
    >
      <v-row align="center">
        <v-col class="grow">
          {{ $t('tags_info') }}
        </v-col>
        <v-col class="shrink">
          <v-btn text small @click="$emit('open', { type: 'tags', name: 'Themen' })">{{ 'Anzeigen' }}</v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <v-alert
      v-if="hiddenPosts && hiddenPosts.length > 0"
      color="grey"
      dark
      icon="mdi-information"
      elevation="2"
      @click="showHidden = !showHidden"
    >
      <v-row align="center">
        <v-col class="grow">
          {{ $tc('hidden_post', hiddenPosts.length) }}
        </v-col>
        <v-col class="shrink">
          <v-btn text small>{{ !showHidden ? 'Anzeigen' : 'Zurück' }}</v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <v-select
      v-if="tagIds"
      v-model="tagId"
      :items="tagsOpts"
      :clearable="tagId !== null"
      label="Thema"
      filled
      hide-details
      class="mb-4"
    />

    <v-select
      v-if="cats"
      v-model="catId"
      :items="catsOpts"
      :clearable="catId !== null"
      label="Kategorie"
      filled
      hide-details
      class="mb-4"
    />

    <div v-if="visiblePostsIdx && visiblePostsIdx.length > 0 && !showHidden" :class="config.inlinePosts ? 'inline-list' : null">
      <v-lazy
        v-for="visiblePostIdx in visiblePostsIdx"
        :key="visiblePostIdx.id"
        min-height="120"
        transition="fade-transition"
      >
        <post-card
          v-if="!config.inlinePosts"
          :postId="visiblePostIdx.id"
          class="mb-4"
          @load="onPostLoad"
          @click="post = $event"
        />

        <post-inline
          v-else
          :postId="visiblePostIdx.id"
          class="mb-4"
          @edit="post = $event; editPostDlg = true"
        />
      </v-lazy>

      <div v-if="isInfinite">
        <v-btn v-if="hasMore || loading" text block :loading="loading" @click="loadMore">{{ $t('show_more') }}</v-btn>
        <div v-else class="text-center">{{ $t('no_more_entries') }}</div>
      </div>
    </div>

    <div v-else-if="hiddenPosts && showHidden">
      <post-card
        v-for="item in hiddenPosts"
        :key="item.id"
        :post="item"
        class="mb-3"
        @click="post = $event"
      />
    </div>

    <v-dialog
      :value="post !== null && !config.inlinePosts"
      fullscreen
      hide-overlay
      persistent
    >
      <v-card class="d-flex flex-column" style="height: 100%;">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="post = null">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-spacer/>
          <v-btn v-if="isEditable(post)" icon @click.stop="editPostDlg = true">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <post-objection-menu v-if="post" :post="post"/>
          <v-btn icon @click="post = null">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="scrollable" style="flex: 1;">
          <post-detail
            v-if="post"
            :post="post"
            @load="post = $event"
            @update="updatedPostId = post.id"
          />
        </div>
      </v-card>
    </v-dialog>

    <fab-btn v-if="canAdd" @click="editPostDlg = true"/>

    <v-dialog
      :value="editPostDlg"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-spacer/>
          <v-btn icon dark @click="editPostDlg = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <post-edit
          v-if="editPostDlg"
          :post="post"
          @beforeSave="onBeforeSave"
          @save="onSave"
          @beforeDelete="onBeforeDelete"
          @delete="onDelete"
        />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import PostCard from '@/components/posts/PostCard'
import PostDetail from '@/components/posts/PostDetail'
import PostEdit from '@/components/posts/PostEdit'
import PostInline from '@/components/posts/PostInline'
import PostObjectionMenu from '@/components/posts/PostObjectionMenu'
import FabBtn from '@/components/base/FabBtn'

export default {
  name: 'PostsView',
  components: {
    PostCard,
    PostDetail,
    PostEdit,
    PostInline,
    PostObjectionMenu,
    FabBtn
  },
  props: {
    config: Object,
    itemId: String
  },
  provide () {
    return {
      config: this.config
    }
  },
  reactiveProvide: {
    name: 'posts',
    include: ['updatedPostId']
  },
  data () {
    return {
      postsIdx: null,
      updatedPostsIdx: null,
      hiddenPosts: null,
      limit: this.config.sortBy ? 1 : 2, // start with the first two blocks, as the newest one could be (nearly) empty
      lastBlockNr: 0, // the last read block nr
      updatedLastBlockNr: 0,
      loading: false, // when loading more
      post: null, // currently open post
      editPostDlg: false,
      tagId: null,
      catId: null,
      showHidden: false,
      acceptNextUpdate: false,
      updatedPostId: null,
    }
  },
  computed: {
    visiblePostsIdx () {
      // filter posts by tagId or catId, if set
      if (this.tagId) {
        return this.postsIdx.filter(p => p.tagIds?.includes(this.tagId))
      } else if (this.catId) {
        return this.postsIdx.filter(p => p.catIds?.includes(this.catId))
      } else {
        return this.postsIdx
      }
    },
    isInfinite () {
      return this.config.sortBy === undefined
    },
    hasMore () {
      return this.lastBlockNr > 0
    },
    tagIds () { // user tagIds!
      return !this.config.collection && Object.keys(this.$root.tags).length > 0
        ? this.$root.user.tagIds?.filter(tagId => Object.keys(this.$root.tags).includes(tagId))
        : null
    },
    tagsOpts () {
      if (!this.tagIds) return null
      return [
        { text: 'Alle', value: null },
        ...this.tagIds
          .map(tagId => ({ text: this.$root.tags[tagId]?.name || '?', value: tagId }))
          .sort((a, b) => a.text < b.text ? -1 : 1)
      ]
    },
    cats () {
      return this.config.collection
        ? (this.$root.views[this.config.id]?.cats && Object.keys(this.$root.views[this.config.id]?.cats).length > 0 ? this.$root.views[this.config.id].cats : null)
        : null
    },
    catsOpts () {
      if (!this.cats) return null
      return [
        { text: 'Alle', value: null },
        ...Object.keys(this.cats)
          .map(catId => ({ text: this.cats[catId], value: catId }))
          .sort((a, b) => a.text < b.text ? -1 : 1)
      ]
    },
    canAdd () {
      if (this.$root.isAdmin) return true
      if (!this.config.collection) {
        // if at least one tag is not protected or current user is moderator
        return !!Object.values(this.$root.tags).find(tag => !tag.protected || tag.moderators?.includes(this.$root.userId))
      } else {
        // if view is not protected or current user is moderator
        return !this.$root.views[this.config.id]?.protected || this.$root.views[this.config.id]?.moderators?.includes(this.$root.userId)
      }
    }
  },
  methods: {
    subscribe () {
      this.unsubscribe()

      this.loading = true
      // this.posts = null
      this.updatedPosts = null
      this.hiddenPosts = null
      this.lastBlockNr = 0

      // define filters
      const filters = []
      // tag(s) filter
      if (!this.config.collection) {
        // user feed (all public, and those matching user tags
        filters.push(p => !p.tagIds || p.tagIds.find(tagId => this.$root.user.tagIds?.includes(tagId)))
      }
      // group filter
      if (!this.$root.isAdmin) {
        filters.push(p => !p.groupIds || p.groupIds.find(groupId => this.$root.userGroupIds?.includes(groupId)))
      }
      // filtered and blocked filters
      filters.push(p => !this.$root.user.filteredPostIds?.includes(p.id))
      filters.push(p => !this.$root.user.blockedUserIds?.includes(p.userId))

      // feed collection
      const collection = this.config.collection || 'feed'
      this.unsubscribeFeed = this.$fb.db.collection('posts-idx/' + collection + '/blocks')
        .orderBy('ts', 'desc')
        .limit(this.limit)
        .onSnapshot(snap => {
          // merge blocks
          let postsIdx = []
          snap.forEach(doc => {
            const block = doc.data()
            postsIdx.push(...block.posts)
          })

          // filter postsIdx
          filters.forEach(filter => {
            postsIdx = postsIdx.filter(filter)
          })

          // sort locally if not default (ts)
          if (this.config.sortBy) {
            postsIdx.sort((a, b) => a[this.config.sortBy] < b[this.config.sortBy] ? -1 : 1)
          }
          // reverse if not locally or if explicitly configured
          if (!this.config.sortBy || this.config.sortDir === 'desc') {
            postsIdx.reverse()
          }

          const lastBlockNr = snap.docs.length > 0 ? snap.docs[snap.docs.length - 1].data().nr : 0

          // // if we have a list already, update it as long as we have no newer added post on top, otherwise queue
          // if (!this.postsIdx || this.postsIdx.length === 0 || postsIdx[0].id === this.postsIdx[0].id) {
          if (!this.postsIdx || this.postsIdx.length === 0 || this.acceptNextUpdate) {
            this.postsIdx = postsIdx
            this.lastBlockNr = lastBlockNr
            this.acceptNextUpdate = false
          } else {
            this.updatedPostsIdx = postsIdx
            this.updatedLlastBlockNr = lastBlockNr
          }

          this.loading = false
        })

      // posts collection
      this.unsubscribePosts = this.$fb.db.collection('posts')
        .where('userId', '==', this.$root.userId)
        .where('blockId', '==', '')
        .onSnapshot(snap => {
          let hiddenPosts = []
          snap.forEach(doc => {
            const post = {
              id: doc.id,
              ...doc.data()
            }
            if ((this.config.collection || '') === (post.collection || '') && !post._deleted) {
              hiddenPosts.push(post)
            }
          })

          // filter posts
          filters.forEach(filter => {
            hiddenPosts = hiddenPosts.filter(filter)
          })

          this.hiddenPosts = hiddenPosts
        })
    },
    unsubscribe () {
      if (this.unsubscribeFeed) {
        this.unsubscribeFeed()
      }
      if (this.unsubscribePosts) {
        this.unsubscribePosts()
      }
    },
    showUpdated () {
      this.postsIdx = [...this.updatedPostsIdx]
      this.updatedPostsIdx = null
    },
    loadMore () {
      this.limit++
      this.subscribe()
    },
    openItem (postId) {
      this.$fb.db.doc('posts/' + postId).get()
        .then(doc => {
          const post = { id: doc.id, ...doc.data() }
          if (post && !post._deleted) {
            this.post = post
          } else {
            this.$root.snack = {
              text: this.$t('item_deleted',),
              color: 'error'
            }
          }
        })
    },
    isEditable (post) {
      return post && (post.userId === this.$root.userId || this.$root.isAdmin)
    },
    onBeforeSave () {
      if (!this.post) {
        this.acceptNextUpdate = true
      }
    },
    onSave () {
      if (this.post?.id) {
        this.updatedPostId = this.post.id
      }
      this.post = null
      this.editPostDlg = false
    },
    onBeforeDelete () {
      this.acceptNextUpdate = true
    },
    onDelete () {
      this.post = null
      this.editPostDlg = false
    },
    onPostLoad (post) {
      if (post && this.post && post.id === this.post.id) {
        this.post = post
      }
    },
    back () {
      if (this.editPostDlg) {
        this.editPostDlg = false
        return true
      } else if (this.post) {
        this.post = null
        return true
      }
    }
  },
  watch: {
    itemId: {
      handler (itemId) {
        if (itemId) {
          this.openItem(itemId)
        }
      },
      immediate: true
    },
    post (post) {
      if (post === null) {
        this.$emit('update:itemId', null)
      }
    },
    updatedPostId (updatedPostId) {
      if (updatedPostId) {
        setTimeout(() => {
          this.updatedPostId = null
        }, 1000)
      }
    }
  },
  created () {
    this.subscribe()
  },
  beforeDestroy () {
    this.unsubscribe()
  }
}
</script>

<style scoped>
.chip-abs {
  position: absolute;
  top: 24px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1;
}
.inline-list .v-lazy:not(:last-child) {
  border-bottom: 1px solid lightgrey;
  border-radius: 0;
}
.inline-list .v-lazy:not(:first-child) {
  margin-top: 16px;
}
</style>
