<template>
  <v-container>
    <v-alert
      v-if="moderated"
      color="grey"
      dark
      icon="mdi-account-supervisor-circle"
      elevation="2"
    >
      Der Beitrag wird vor dem Publizieren überprüft.
    </v-alert>

    <v-form>
      <img-upload v-model="img"/>

      <v-text-field v-model="title" label="Titel" @focus="lastFocus = 'title'"/>

      <quill-editor
        v-model="text"
        label="Text"
        @focus="lastFocus = 'text'"
      />

      <div v-if="tagsOpts">
        <v-select
          v-model="tagIds"
          :items="tagsOpts"
          :rules="!$root.isAdmin ? [v => v && v.length > 0 || 'Bitte Thema / Themen wählen'] : []"
          label="Thema / Themen"
          :placeholder="$root.isAdmin ? 'leer = an alle!' : null"
          persistent-placeholder
          chips
          multiple
          @focus="lastFocus = null"
        />
      </div>

      <div v-else-if="catsOpts">
        <v-select
          v-model="catIds"
          :items="catsOpts"
          :rules="!$root.isAdmin ? [v => v && v.length > 0 || 'Bitte Kategorien wählen'] : []"
          label="Kategorie / Kategorien"
          :placeholder="$root.isAdmin ? 'leer = in allen Kategorien' : null"
          persistent-placeholder
          chips
          multiple
          @focus="lastFocus = null"
        />
      </div>

      <v-select
        v-if="$root.groupOpts"
        v-model="groupIds"
        :items="$root.groupOpts"
        label="Gruppen (nur sichtbar für..)"
        :placeholder="$root.isAdmin ? 'leer = für alle sichtbar' : null"
        persistent-placeholder
        chips
        multiple
        @focus="lastFocus = null"
      />

      <!-- <v-text-field
        v-model="reactions"
        label="Reaktionen"
        readonly
        clearable
        @click="clickReactions"
      /> -->

      <plug-price-edit v-if="plugins.includes('price')" v-model="price"/>
      <plug-address-edit v-if="plugins.includes('address')" v-model="address" prefill/>

      <files-field v-if="showFiles" v-model="files" label="Dateien"/>

      <date-time v-if="showSchedule" v-model="schedule" :min="$dayjs().valueOf()" label="Veröffentlichung"/>

      <v-alert
        v-if="showForeignWarning"
        type="warning"
        class="my-5"
      >
        Du hast Themen gewählt, die Du selber nicht abonniert hast. Deshalb wirst du den Beitrag auch selber nicht sehen, solange du nicht mindestens eines dieser Themen abonnierst.
      </v-alert>

      <div class="d-flex justify-center my-5">
        <v-btn icon :disabled="!lastFocus" @click="showEmojiPicker = !showEmojiPicker">
          <v-icon>mdi-emoticon-outline</v-icon>
        </v-btn>

        <v-btn icon :disabled="files && files.length > 0" @click="showFiles = !showFiles">
          <v-icon>mdi-attachment</v-icon>
        </v-btn>

        <v-btn color="primary" rounded :disabled="!isFormValid" :loading="posting" class="mx-3" @click="submitPost">Speichern</v-btn>

        <v-btn icon @click="showSchedule = !showSchedule">
          <v-icon>mdi-clock-outline</v-icon>
        </v-btn>

        <v-btn icon :disabled="!post || !post.id" @click="showDeletePost = true">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
    </v-form>

    <v-dialog
      v-model="showEmojiPicker"
      transition="dialog-bottom-transition"
    >
      <v-emoji-picker v-if="showEmojiPicker" @select="selectEmoji"/>
    </v-dialog>

    <confirm-dlg
      v-model="showDeletePost"
      text="Beitrag löschen?"
      @ok="deletePost"
    />
  </v-container>
</template>

<script>
import { VEmojiPicker } from 'v-emoji-picker'
import DateTime from '@/components/base/DateTime'
import FilesField from '@/components/base/FilesField'
import ImgUpload from '@/components/base/ImgUpload'
import QuillEditor from '@/components/base/QuillEditor'
import ConfirmDlg from '@/components/base/ConfirmDlg'
import PlugsMixin from '@/mixins/PlugsMixin'
import PlugAddressEdit from '@/components/plugs/PlugAddressEdit'
import PlugPriceEdit from '@/components/plugs/PlugPriceEdit'

export default {
  name: 'PostEdit',
  components: {
    DateTime,
    FilesField,
    ImgUpload,
    QuillEditor,
    ConfirmDlg,
    PlugAddressEdit,
    PlugPriceEdit,
    VEmojiPicker
  },
  mixins: [PlugsMixin],
  inject: ['config'],
  props: {
    post: Object
  },
  data () {
    return {
      title: null,
      text: null,
      img: null,
      files: null,
      tagIds: null,
      catIds: null,
      groupIds: null,
      reactions: '❤️',
      schedule: null,
      price: null,
      address: null,
      posting: false,
      lastFocus: null,
      showEmojiPicker: false,
      showFiles: false,
      showSchedule: false,
      showDeletePost: false
    }
  },
  computed: {
    tagsOpts () {
      if (this.config.collection) return null // no tags for mode=collection
      return Object.keys(this.$root.tags)
        .map(tagId => ({ text: this.$root.tags[tagId].name, value: tagId }))
    },
    catsOpts () {
      if (!this.config.collection) return null // no cats for mode=feed
      if (!this.config.cats) return null
      return Object.keys(this.config.cats)
        .map(catId => ({ text: this.config.cats[catId], value: catId }))
    },
    moderated () {
      if (this.$root.isAdmin) return false
      if (!this.config.collection) {
        return this.tagIds?.find(tagId => this.$root.tags[tagId].moderated && !this.$root.tags[tagId].moderators?.includes(this.$root.userId))
      } else {
        return this.config.moderated && !this.config.moderators?.includes(this.$root.userId)
      }
    },
    isFormValid () {
      return this.title &&
        (this.$root.isAdmin || (!this.config.collection ? (!this.tagsOpts || this.tagIds) : (!this.catsOpts || this.catIds)))
    },
    showForeignWarning () { // check if posting to unsubscribed tagIds => warn
      if (!this.isFormValid) return false
      if (!this.config.collection) {
        return this.tagIds && this.tagIds.find(tagId => !this.$root.user.tagIds.includes(tagId))
      } else {
        return false
      }
    }
  },
  methods: {
    selectEmoji (emoji) {
      if (this[this.lastFocus] === null) {
        this[this.lastFocus] = ''
      }
      this[this.lastFocus] += emoji.data
      this.showEmojiPicker = false
    },
    async submitPost () {
      this.$emit('beforeSave')
      this.showEmojiPicker = false
      this.posting = true
      const savePostFunc = this.$fb.fn.httpsCallable('savePost')
      const post = {
        id: this.post ? this.post.id : null,
        viewId: this.config.id,
        collection: this.config.collection || null,
        title: this.title,
        text: this.text,
        img: this.img,
        files: this.files,
        tagIds: this.tagIds,
        catIds: this.catIds,
        groupIds: this.groupIds,
        reactions: this.reactions,
        schedule: this.schedule,
        price: this.price,
        address: this.address
      }
      const response = await savePostFunc(post)
      if (response.data.ok) {
        this.$emit('save'/*, {
          id: response.data.id,
          ...response.data.data
        } */)
      }
      this.posting = false
    },
    async deletePost () {
      this.$emit('beforeDelete')
      const deletePostFunc = this.$fb.fn.httpsCallable('deletePost')
      const response = await deletePostFunc({ id: this.post.id })
      if (response.data.ok) {
        this.$emit('delete')
      }
    },
    clickReactions () {
      if (this.lastFocus !== 'reactions') {
        this.lastFocus = 'reactions'
      }
      this.showEmojiPicker = true
    }
  },
  watch: {
    lastFocus (lastFocus) {
      if (lastFocus === null) {
        this.showEmojiPicker = false
      }
    }
  },
  created () {
    if (this.post) {
      this.title = this.post.title
      this.text = this.post.text
      this.img = this.post.img
      this.files = this.post.files || null
      this.showFiles = this.files && this.files.length > 0
      this.tagIds = this.post.tagIds
      this.catIds = this.post.catIds
      this.groupIds = this.post.groupIds
      this.schedule = this.post.schedule ? this.post.schedule.toMillis() : null
      this.showSchedule = this.schedule !== null
      this.price = this.post.price
      this.address = this.post.address
    } else {
      if (this.config.defaultReactions) {
        this.reactions = this.config.defaultReactions
      }
    }
  }
}
</script>
